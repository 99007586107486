<template>
  <tr >
    <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
    <td style="padding: 0px;">
      <select class="form-control" v-model="cr_dr" @change="cr_dr_changed" autofocus >
        <option value="1">Cr</option>
        <option value="-1">Dr</option>
      </select>
    </td>
    <td style="padding: 0px;">
      <select class="form-control" v-if="detail" v-model="detail.ledger.code" >
        <option v-for="ledger in ledgers" v-bind:value="ledger.code">
          {{ ledger.name }}
        </option>
      </select>
    </td>
    <td style="padding: 0px;">
      <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.credit"  @change="row_value_changed(index,4)"  :disabled="!(is_credit)"/>
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.debit" @change="row_value_changed(index,5)" :disabled="is_credit"/>
    </td>
    <td style="padding: 0px;text-align: right;">
      <button type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow()" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
  export default {
    name: 'JournalDetailRow',
    props:{
      ledgers:{
        type: Array,
        default: () => []
      },
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"adjust_type":0,"ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""}},"debit":0,"credit":0,"remarks":""}')
      },
      index: {
        type: Number,
        default: () => 0
      },
    },
    beforeMount () {
      this.detail = this.myrow;
      if (this.$data.detail.credit > 0) {
        this.$data.is_credit = true;
        this.$data.cr_dr = 1;
      }else{
        this.$data.is_credit = false;
        this.$data.cr_dr = -1;
      }
    },
    data() {
      return {
        cr_dr : 1,
        is_credit:true,
        detail : JSON.parse('{"idx":0,"adjust_type":0,"ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""}},"debit":0,"credit":0,"remarks":""}'),
      }
    },
    mounted(){

    },
    methods:{
      removeRow(){
        this.$emit('onRemoveRow', this.$props.index );
      },
      cr_dr_changed(){
        this.$data.is_credit = this.$data.cr_dr == 1;
      },
      row_value_changed(rowIndex, colIndex){
        this.$emit('onrow_change',rowIndex, colIndex);
      }
    }
  }
</script>

<style scoped>

</style>
