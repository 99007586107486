<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline"  style="background-color: whitesmoke; padding: 10px;" >
      <h5 v-if="voucher.code == 0" class="card-title">Journal Creation</h5>
      <h5 v-if="voucher.code > 0" class="card-title"> Doc No: {{voucher.doc_no}} - Journal Update</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>


    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <!--<div class="form-group form-group-material">-->
            <!--<label class="control-label text-semibold">Paid Ledger</label>-->
            <!--<select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger.code" >-->
              <!--<option v-for="ledger in ledgers" v-bind:value="ledger.code">-->
                <!--{{ ledger.name }}-->
              <!--</option>-->
            <!--</select>-->
          <!--</div>-->
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" autofocus>
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th style="text-align: left;">Cr/Dr</th>
              <th >Particulars</th>
              <th >Description</th>
              <th style="width:150px; text-align: right;">Credit</th>
              <th style="width:150px; text-align: right;">Debit</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-if="voucher" v-for="(detail,index) in voucher.details">
              <JournalDetailRow  v-if="detail" :ledgers="ledgers" :index="index" :myrow="detail" v-on:onrow_change="sumRowAmountTotal" v-on:onRemoveRow="removeRow" ></JournalDetailRow>

              <!--<tr v-if="voucher" v-for="(detail,index) in voucher.details" >-->
              <!--<td style="text-align: center;padding: 0px;"> {{index + 1}} </td>-->
              <!--<td style="padding: 0px;">-->
                <!--<select class="form-control"  >-->
                  <!--<option value="cr">Cr</option>-->
                  <!--<option value="dr">Dr</option>-->
                <!--</select>-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<select class="form-control" v-if="detail" v-model="detail.ledger.code" >-->
                  <!--<option v-for="ledger in ledgers" v-bind:value="ledger.code">-->
                    <!--{{ ledger.name }}-->
                  <!--</option>-->
                <!--</select>-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.credit" @change="sumRowAmountTotal" />-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.debit" @change="sumRowAmountTotal" />-->
              <!--</td>-->
              <!--<td style="padding: 0px;text-align: right;">-->
                <!--<button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>-->
              <!--</td>-->
            <!--</tr>-->

            </tbody>
            <tfoot>
            <tr>
              <td style="padding: 0px;">
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold text-right" >&#8377;<span style="padding-right: 0px">{{ indianFormat(creditTotal) }}</span></td>
              <td class="font-weight-bold text-right" >&#8377;<span style="padding-right: 0px">{{ indianFormat(debitTotal) }}</span></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>


  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import JournalDetailRow from '@/components/core/JournalDetailRow.vue'

  export default {
    name: 'JournalForm',
    store,
    components:{
      JournalDetailRow
    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        creditTotal: 0.0,
        debitTotal: 0.0,
        voucher: JSON.parse('{"id":"","code":0,"type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      }
    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    created () {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      let self = this;
      self.loadData();

      if( self.$data.voucher.doc_date == '0001-01-01' ){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }

      $('#txtDate').focus();
    },
    methods:{

      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeThis(){
        this.$emit('journal_window_closed');
        if(this.voucher.code > 1){
          this.$modal.hide('voucher-window');
          this.voucher = JSON.parse('{"id":"","code":0,"type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}');
        }else{
          this.$router.push("/")
        }

      },
      addRow(){
        const self = this;
        this.$data.voucher.details.push(JSON.parse('{"idx":0,"adjust_type":0,"ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""}},"debit":0,"credit":0,"remarks":""}'));
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);
      },
      sumRowAmountTotal() {
        let self = this;

        self.$data.debitTotal = 0.0;
        self.$data.creditTotal = 0.0;
        self.$data.voucher.details.forEach(function (detail) {
          self.$data.creditTotal += parseFloat(detail.credit);
          self.$data.debitTotal += parseFloat(detail.debit);
        });
      },
      clear(){
        let self = this;
        self.$data.voucher = JSON.parse('{"id":"","code":0,"type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}');
        self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.details = [];
        self.$data.debitTotal =0.0;
        self.$data.creditTotal = 0.00;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.details.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self = this;

        self.$data.voucher.finyear = store.state.user.finyear;
        self.$data.voucher.vchtype = 1008;
        self.$data.voucher.ibr = parseInt(localStorage.getItem('branch_code'));
        self.$data.voucher.details.forEach(function (detail, i){
          detail.idx = i;
          detail.credit = parseFloat(detail.credit);
          detail.debit = parseFloat(detail.debit);
        });


        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));

        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myvoucher.ref_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myvoucher.amount = self.$data.debitTotal;


        const requestOptions = {
          method:  ((self.$data.voucher.id.length == 0) ? 'POST' : 'PUT'),
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/voucher/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            swal({text: resp.message, type: 'success', onClose: () => { $('#txtDate').focus()}, timer:1500 });
            self.clear();
            self.$emit('journal_saved');
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error", onClose: () => { $('#txtDate').focus()}, timer:3000});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error", onClose: () => { $('#txtDate').focus()}, timer:3000});
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

</style>
